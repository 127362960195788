<template>
  <div>
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix">
            <div class="page-title-left">
              <h5 class="page-title-heading"><div class="breadcrumbs"><router-link to="/admin/accounting" class="no-link">Accounting</router-link></div> Income Statement</h5>
            </div>
          </div>

          <div class="widget-list">

            <div class="widget-holder">
              <div class="widget-bg">
                <div class="widget-body">
                  <div class="row">
                    <div class="col">
                      <router-link :to="`/admin/accounting/reports/income/${Number(year) - 1}`"><i class="fal fa-chevron-left fa-fw mr-2"></i></router-link>
                      <h6 class="d-inline-block fw-400 mt-2">{{ year }}</h6>
                      <router-link :to="`/admin/accounting/reports/income/${Number(year) + 1}`"><i class="fal fa-chevron-right fa-fw ml-2"></i></router-link>
                    </div>
                    <div class="col">
                      <h6 class="fw-400 text-right mt-2 mb-2 pr-2">Net Income: {{ totalIncome + totalExpense | formatAmount(true) }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="widget-holder">
              <div class="widget-bg">
                <div class="widget-body">
                  <h6 class="fw-400 text-right mt-2 mb-2 pr-2">Total Revenue: {{ totalIncome | formatAmount(true) }}</h6>
                  <table class="table" v-if="incomeAccounts.length > 0">
                    <thead>
                      <tr><th>Description</th><th class="text-right">Amount</th></tr>
                    </thead>
                    <tbody>
                      <tr v-for="a in incomeAccounts" :key="a.id" :class="a.active ? '' : 'text-muted'">
                        <td><router-link :to="`/admin/accounting/ledger/${a.id}/${year}`" class="no-link">{{ a.name }}</router-link></td>
                        <td class="text-right">{{ a.ending_balance | formatAmount(false) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="widget-holder">
              <div class="widget-bg">
                <div class="widget-body">
                  <h6 class="fw-400 text-right mt-2 mb-2 pr-2">Total Expenses: {{ totalExpense | formatAmount(true) }}</h6>
                  <table class="table" v-if="expenseAccounts.length > 0">
                    <thead>
                      <tr><th>Description</th><th class="text-right">Amount</th></tr>
                    </thead>
                    <tbody>
                      <tr v-for="a in expenseAccounts" :key="a.id" :class="a.active ? '' : 'text-muted'">
                        <td><router-link :to="`/admin/accounting/ledger/${a.id}/${year}`" class="no-link">{{ a.name }}</router-link></td>
                        <td class="text-right">{{ a.ending_balance | formatAmount(false) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin, FormDatepickerPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);
Vue.use(FormDatepickerPlugin);

export default {
  data() {
    return {
      year: this.$route.params.year ? this.$route.params.year : new Date().getFullYear(),
      accounts: [],
    };
  },
  computed: {
    incomeAccounts() {
      const accounts = [];
      for (let i = 0; i < this.accounts.length; i += 1) {
        if (this.accounts[i].type === 'income' && this.accounts[i].ending_balance !== 0) {
          accounts.push(this.accounts[i]);
        }
      }
      return accounts;
    },
    totalIncome() {
      let total = 0;
      for (let i = 0; i < this.accounts.length; i += 1) {
        if (this.accounts[i].type === 'income' && this.accounts[i].ending_balance !== 0) {
          total += this.accounts[i].ending_balance;
        }
      }
      return total;
    },
    expenseAccounts() {
      const accounts = [];
      for (let i = 0; i < this.accounts.length; i += 1) {
        if (this.accounts[i].type === 'expense' && this.accounts[i].ending_balance !== 0) {
          accounts.push(this.accounts[i]);
        }
      }
      return accounts;
    },
    totalExpense() {
      let total = 0;
      for (let i = 0; i < this.accounts.length; i += 1) {
        if (this.accounts[i].type === 'expense' && this.accounts[i].ending_balance !== 0) {
          total += this.accounts[i].ending_balance;
        }
      }
      return total;
    },
  },
  filters: {
    formatAmount(value, includeDollarSign) {
      const v = value * -1; // flip sign for reports
      const amt = (Math.abs(v) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return `${v < 0 ? '(' : ''}${includeDollarSign ? '$' : ''}${amt}${v < 0 ? ')' : ''}`;
    },
  },
  methods: {
    getAccounts() {
      this.$api.get(`/accounting/accounts/all/${this.year}`)
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
  },
  async mounted() {
    try {
      if (!this.$auth.profile.site_admin) {
        this.$store.commit('error', 404);
        return;
      }
      document.title = 'Income Statement - Accounting - Admin - Agiler';
      this.getAccounts();
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
  },
};
</script>
